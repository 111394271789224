import React from 'react'
import { Space, Text } from 'components'
import { css } from 'emotion'

const CartItemView = ({ count, name, price, CancelButtonElement }) => (
  <div className={styles.container}>
    <Text content h4 white className={styles.count}>
      {count}
    </Text>
    <Text content h4 white className={styles.name}>
      {name}
    </Text>
    <Text content h4 white>{`$${(count * price).toFixed(2)}`}</Text>
    <Space w1 />
    {CancelButtonElement}
  </div>
)

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  count: css({
    minWidth: '2rem',
  }),
  name: css({
    flex: 1,
  }),
}

export default CartItemView
