import React from 'react'
import { CFView, Text } from 'components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css } from 'emotion'

const LocationSelectButtonView = ({ id, name, address }) => (
  <CFView key={id} row alignCenter w="100%" mv="1rem" className={styles.button}>
    <FontAwesomeIcon icon="map-marker" className={styles.icon} />
    <CFView column ml="2rem" fill>
      <Text heading2 h3 white>
        {name}
      </Text>
      <Text content h3 white>
        {address}
      </Text>
    </CFView>
    <FontAwesomeIcon icon="chevron-right" className={styles.icon} />
  </CFView>
)

const styles = {
  button: css({
    borderRadius: '1rem',
    backgroundColor: 'rgba(151,151,151,.3)',
    padding: '1rem',
    cursor: 'pointer',
  }),
  icon: css({
    color: 'white',
    fontSize: '2.5rem',
  }),
}

export default LocationSelectButtonView
