import React from 'react'
import { Text } from 'components'
import { css } from 'emotion'

const EmptyRecentOrderView = () => (
  <div className={styles.recent}>
    <Text heading h2 white center>
      No Recent Orders Yet
    </Text>
  </div>
)

const styles = {
  recent: css({
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    padding: '8rem',
  }),
}

export default EmptyRecentOrderView
