import React from 'react'
import { CFView, CFText, Space } from 'components'

export default ({
  address,
  delivery,
  waitTime,
  deliveryTime,
  minOrder,
  EditAddressButtonElement,
}) => (
  <CFView column>
    {delivery && (
      <CFView row>
        <CFText h4 white>
          Address: {address}
        </CFText>
        <Space w2 />
        {EditAddressButtonElement}
      </CFView>
    )}
    <CFText h4 white>
      {delivery
        ? `Estimated Delivery Time: ${waitTime + deliveryTime} minutes`
        : `Estimated Pickup Time: ${waitTime} minutes`}
    </CFText>
    {delivery && <CFText h4 white>{`Minimum Subtotal: $${minOrder}`}</CFText>}
  </CFView>
)
