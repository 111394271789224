export * from 'cf-web/src/components'
export * from 'cf-core/src/components'
export { default as CFSelect } from 'cf-select'
export { default as MediaQuery } from 'react-responsive'

export { default as IconLink } from './IconLink'
export { default as LogoView } from './LogoView'
export { default as NavbarView } from './NavbarView'
export { default as PageHeader } from './PageHeader'
export { default as LocationSelectNameView } from './LocationSelectNameView'
export { default as LocationSelectModalView } from './LocationSelectModalView'
export { default as LocationSelectButtonView } from './LocationSelectButtonView'
export { default as OrderStatusView } from './OrderStatusView'
export { default as Container } from './Container'
export { default as Text } from './Text'

// Buttons
export { default as AddButtonView } from './Buttons/AddButtonView'
export { default as CancelButtonView } from './Buttons/CancelButtonView'
export { default as CheckButtonView } from './Buttons/CheckButtonView'
export { default as EditButtonView } from './Buttons/EditButtonView'
export { default as OrderButtonView } from './Buttons/OrderButtonView'
export { default as PaintedButtonView } from './Buttons/PaintedButtonView'
export { default as PrimaryButtonView } from './Buttons/PrimaryButtonView'
export { default as SubtractButtonView } from './Buttons/SubtractButtonView'
export { default as TextButtonView } from './Buttons/TextButtonView'
export { default as ToggleButtonView } from './Buttons/ToggleButtonView'

// User
export { default as Card } from './Card'

// Menu
export { default as StoreClosedBannerView } from './StoreClosedBannerView'
export { default as RadioButtonView } from './RadioButtonView'

// Review
export { default as ReviewItemView } from './Review/ReviewItemView'
export { default as ReviewModalView } from './Review/ReviewModalView'
export {
  default as ReviewRatingButtonView,
} from './Review/ReviewRatingButtonView'
export { default as ReviewSkipButtonView } from './Review/ReviewSkipButtonView'
export {
  default as ReviewSubmitButtonView,
} from './Review/ReviewSubmitButtonView'

export const reviewCommentInputProps = {
  placeholder: 'Comments',
}
