import React, { Component } from 'react'
import { css } from 'emotion'
import { HorizontalLine, Space } from 'components'

class SummaryView extends Component {
  render() {
    const { CartItemListElement, CartTotalElement } = this.props
    return (
      <>
        {CartItemListElement}
        <Space h1 />
        <HorizontalLine />
        <Space h1 />
        <div className={styles.total}>{CartTotalElement}</div>
      </>
    )
  }
}

const styles = {
  total: css({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  }),
}

export default SummaryView
