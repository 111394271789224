import React from 'react'
import { Text, CFView } from 'components'

export default ({ name, RatingButtonElement }) => (
  <CFView row justifyBetween alignCenter h="3rem">
    <Text content h4>
      {name}
    </Text>
    <CFView row>{RatingButtonElement}</CFView>
  </CFView>
)
