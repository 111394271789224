import React from 'react'
import { css } from 'emotion'
import { ToggleButtonView } from 'components'

export default ({ name, selected }) => (
  <ToggleButtonView label={name} className={styles.button} toggled={selected} />
)

const styles = {
  button: css({
    flex: 1,
  }),
}
