import React from 'react'
import { Text } from 'components'
import { css } from 'emotion'

const SummaryTotalView = ({
  subTotalFormatted,
  taxFormatted,
  totalFormatted,
  tipFormatted,
  deliveryFeeFormatted,
  orderType,
}) => (
  <>
    <div className={styles.totalRow}>
      <Text content h4 white>
        Subtotal
      </Text>
      <Text content h4 white>
        ${subTotalFormatted}
      </Text>
    </div>
    {orderType === 'Delivery' && (
      <div className={styles.totalRow}>
        <Text content h4 white>
          Delivery Fee
        </Text>
        <Text content h4 white>
          ${deliveryFeeFormatted}
        </Text>
      </div>
    )}
    <div className={styles.totalRow}>
      <Text content h4 white>
        GST (5%)
      </Text>
      <Text content h4 white>
        ${taxFormatted}
      </Text>
    </div>
    <div className={styles.totalRow}>
      <Text content h4 white>
        Tip
      </Text>
      <Text content h4 white>
        ${tipFormatted}
      </Text>
    </div>
    <div className={styles.totalRow}>
      <Text content h4 white>
        Total
      </Text>
      <Text content h4 white>
        ${totalFormatted}
      </Text>
    </div>
  </>
)

const styles = {
  totalRow: css({
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '3rem',
  }),
}

export default SummaryTotalView
