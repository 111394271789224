import React from 'react'
import { Text, Space } from 'components'
import { css } from 'emotion'

const RewardItemView = ({
  count,
  name,
  productId,
  totalDiscountSubtotal,
  CancelButtonElement,
}) => (
  <div className={styles.container}>
    <Text content h4 white className={styles.count}>
      {count}
    </Text>
    <Text content h4 white className={styles.name}>
      {name}
    </Text>
    <Text content h4 white>
      {productId || !totalDiscountSubtotal ? (
        <Text content h4 white>
          FREE
        </Text>
      ) : (
        <Text content h4 white>{`-$${totalDiscountSubtotal.toFixed(2)}`}</Text>
      )}
    </Text>
    <Space w1 />
    {CancelButtonElement}
  </div>
)

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  count: css({
    minWidth: '2rem',
  }),
  name: css({
    flex: 1,
  }),
}

export default RewardItemView
